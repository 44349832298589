<template>
  <div class="container round px-0 mb-5 pb-5">
    <div class="page-header mb-1">
      <strong>งวดหวยรอออกผล</strong>
    </div>
    <div class="card position-relative">
      <table class="table table-striped table-hover table-bordered table-warning mb-0">
        <thead>
          <tr>
            <th colspan="2">หวย</th>
            <th>งวด</th>
            <th>เวลาเปิด</th>
            <th>เวลาปิด</th>
            <th>จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item._id">
            <td width="80" class="p-0 text-center">
              <img v-if="item.market" :src="item.market.imageIcon" width="60">
            </td>
            <td>{{item.note.groupTitle}} ✦ {{item.note.marketTitle}}</td>
            <td class="text-center">{{item.roundDate.date}}</td>
            <td class="text-center">{{item.roundDate.open | dispDateTime}}<br />{{item.roundDate.open | dispDateTime('HH:mm:ss')}}</td>
            <td class="text-center">{{item.roundDate.close | dispDateTime}}<br />{{item.roundDate.close | dispDateTime('HH:mm:ss')}}</td>
            <td class="text-center">
              <button class="btn btn-secondary btn-sm" @click="toSetting(item)">ตั้งค่า</button>
              <button class="btn btn-success btn-sm ml-1" @click="toAward(item)">ออกผล</button>
              <!-- <button class="btn btn-danger btn-sm ml-1" @click="cancelRound(item)">ยกเลิก/คืนเงิน</button> -->
            </td>
          </tr>
          <tr v-if="!isLoading && !items.length">
            <td colspan="6" class="text-center">ไม่มีงวดรอออกผล</td>
          </tr>
        </tbody>
      </table>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Swal from 'sweetalert2'
import _ from 'lodash'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'WaitResults',
  components: {
    Loading
  },
  data() {
    return {
      data: null,
      isLoading: false
    }
  },
  computed: {
    markets() {
      return this.$store.state.markets
    },
    items() {
      if(!this.data)
        return []

      return _.orderBy(this.data.rounds, ['roundDate.close'], ['desc'])
      .map((round)=>{
        round.market = this.markets.find((market)=>{
          return market._id === round.marketId
        })
        return round
      })
    }
  },
  methods: {
    getRounds() {
      this.isLoading = true
      LottoService.getRoundWaitResults()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    updateGlobal(item) {
      this.$store.commit('updateGlobalMarket', {
        groupId: item.groupId,
        marketId: item.marketId,
        round: {
          roundId: item._id,
          roundDate: item.roundDate
        }
      })
    },
    toSetting(item) {
      this.updateGlobal(item)
      this.$router.push({
        name: 'LottoSettings'
      })
    },
    toAward(item) {
      this.updateGlobal(item)
      this.$router.push({
        name: 'UpdateResult'
      })
    },
    cancelRound(item) {
      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})

      Swal.fire({
        title: 'ยกเลิกงวดหวย!',
        text: `[${item.note.groupTitle}] ${item.note.marketTitle} งวดวันที่ ${item.roundDate.date}`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'กลับ',
        reverseButtons: false,
        buttonsStyling: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.cancelRound(item._id, uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.showValidationMessage(
              `ผิดพลาด: ${error.message}`
            )
          })
        },
      })
      .then((res)=>{
        if(res.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ยกเลิกงวดหวยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.getRounds()
          })
        }
      })
    }
  },
  mounted() {
    this.getRounds()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
